<template>
    <header id="header" class="video-header">
      <div class="video-header__wrapper">
        <video id="header-video" controls="false" loop="loop" false autoplay muted playsinline>
          <source src="" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
      <!-- <img src="images/header-img.jpg" alt="" class="video-header__img"> -->
      <div class="">
        <div class="video-header__hl">
          <!-- INTRĂ ÎN<br>LEGION OF HEROES!
          <span>Înregistrează produsul și beneficiază de <strong>Lenovo Legion Pack</strong>.</span> -->
          <img src="images/hl-2.png" alt="">
        </div> 
        <!-- <img src="images/characters-img.png" alt="" class="video-header__chars"> -->
        <!-- <img src="images/chair2.png" alt="" class="video-header__bottom"> -->
      </div>
    </header>
</template>



<script>

  export default {
      
  }

</script>
