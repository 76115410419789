<template>
  <div id="registration" class="registration">
    <div class="container container-flex-row">
      <!-- Ribbon Column -->
      <div class="registration__wrapper">
        <div class="registration__left">
          <div class="registration__left-mobile-wrapper">
            <div class="registration__left--title">
              Fă cunoștință cu UNLOCKER.
              <span>Înscrie laptopul tău Legion aici și primești acces instant în Legion of Heroes</span>
            </div>
            <div class="registration__wrapper-char--mob">
              <img src="images/unlocker2.png" alt />
            </div>
          </div>

          <!-- First Ribbon -->
          <div class="registration__left--ribbon">
            <div class="registration__left--ribbon__info">
              <div
                class="registration__left--ribbon__info--text"
              >Înregistrează orice laptop Legion Y540, Y700 sau Y740 cumpărat și alătură-te Legion Heroes.</div>
              <div class="registration__left--ribbon__info--link">
                <strong>
                  <a href="#specs">vezi produsele din campanie</a>
                </strong>
              </div>
            </div>
            <div class="registration__left--ribbon__icon">
              <a href="#specs"><img src="images/registration-icon.jpg" alt /></a>
            </div>
          </div>
          

          <!-- Second Ribbon -->
          <div class="registration__left--ribbon ribbon-highlighted">
            <div class="registration__left--ribbon__info">
              <div
                class="registration__left--ribbon__info--text"
              >Obții instant 25% discount pentru orice accesoriu Lenovo Legion la alegere.</div>
              <div class="registration__left--ribbon__info--link">
                <strong>
                  <a href="#regulament">vezi regulament</a>
                </strong>
              </div>
            </div>
            <div class="registration__left--ribbon__icon">
              <a href="#regulament"><img src="images/discount-icon-2.png" alt /></a>
            </div>
          </div>

          <!-- Third Ribbon -->
          <div class="registration__left--ribbon">
            <div class="registration__left--ribbon__info">
              <div class="registration__left--ribbon__info--text">
                Primești gratuit Upgrade sau Heal în maxim 5 zile pentru sistemul tău în service-ul
                autorizat Lenovo*!
              </div>
              <div class="registration__left--ribbon__info--link">
                <strong>
                  <a href="#upgrade">completeaza cererea</a>
                </strong>
              </div>
            </div>
            <div class="registration__left--ribbon__icon">
              <a href="#upgrade"><img src="images/upgrade-icon.jpg" alt /></a>
            </div>
          </div>
        </div>
        <div class="registration__wrapper-char--tab">
          <img src="images/unlocker1.jpg" alt />
        </div>
      </div>

      <!-- Form Column -->
      <div class="registration__right">
        <form id="register" class="register">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              v-model="client.lastname"
              id="name"
              name="nume"
              maxlength="25"
              required
            />
            <span class="floating-label">Nume</span>
            <span class="input-icon">
              <svg
                version="1.1"
                id="name-icon"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                style="enable-background:new 0 0 512 512;"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148
                                  C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962
                                  c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216
                                  h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40
                                  c59.551,0,108,48.448,108,108S315.551,256,256,256z"
                    />
                  </g>
                </g>
              </svg>
            </span>
            <span v-if="this.errors.lastname" class="error-message">{{this.errors.lastname}}</span>
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              v-model="client.firstname"
              id="surname"
              name="prenume"
              maxlength="25"
              required
            />
            <span class="floating-label">Prenume</span>
            <span class="input-icon">
              <svg
                version="1.1"
                id="surname-icon"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                style="enable-background:new 0 0 512 512;"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148
                                    C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962
                                    c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216
                                    h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40
                                    c59.551,0,108,48.448,108,108S315.551,256,256,256z"
                    />
                  </g>
                </g>
              </svg>
            </span>
            <div v-if="this.errors.firstname" class="error-message">{{this.errors.firstname}}</div>
          </div>

          <div class="form-group">
            <input
              type="text"
              class="form-control"
              v-model="client.email"
              id="email"
              name="email"
              maxlength="125"
              required
            />
            <span class="floating-label">Adresă e-mail</span>
            <span class="input-icon">
              <svg
                version="1.1"
                id="envelope-icon"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 483.3 483.3"
                style="enable-background:new 0 0 483.3 483.3;"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M424.3,57.75H59.1c-32.6,0-59.1,26.5-59.1,59.1v249.6c0,32.6,26.5,59.1,59.1,59.1h365.1c32.6,0,59.1-26.5,59.1-59.1
                                  v-249.5C483.4,84.35,456.9,57.75,424.3,57.75z M456.4,366.45c0,17.7-14.4,32.1-32.1,32.1H59.1c-17.7,0-32.1-14.4-32.1-32.1v-249.5
                                  c0-17.7,14.4-32.1,32.1-32.1h365.1c17.7,0,32.1,14.4,32.1,32.1v249.5H456.4z"
                    />
                    <path
                      d="M304.8,238.55l118.2-106c5.5-5,6-13.5,1-19.1c-5-5.5-13.5-6-19.1-1l-163,146.3l-31.8-28.4c-0.1-0.1-0.2-0.2-0.2-0.3
                                  c-0.7-0.7-1.4-1.3-2.2-1.9L78.3,112.35c-5.6-5-14.1-4.5-19.1,1.1c-5,5.6-4.5,14.1,1.1,19.1l119.6,106.9L60.8,350.95
                                  c-5.4,5.1-5.7,13.6-0.6,19.1c2.7,2.8,6.3,4.3,9.9,4.3c3.3,0,6.6-1.2,9.2-3.6l120.9-113.1l32.8,29.3c2.6,2.3,5.8,3.4,9,3.4
                                  c3.2,0,6.5-1.2,9-3.5l33.7-30.2l120.2,114.2c2.6,2.5,6,3.7,9.3,3.7c3.6,0,7.1-1.4,9.8-4.2c5.1-5.4,4.9-14-0.5-19.1L304.8,238.55z"
                    />
                  </g>
                </g>
              </svg>
            </span>
            <div v-if="this.errors.email" class="error-message">{{this.errors.email}}</div>
          </div>

          <div class="form-group">
            <input
              type="text"
              class="form-control"
              v-model="client.sn"
              id="serial"
              name="cod produs"
              maxlength="125"
              required
            />
            <span class="floating-label">Cod produs (serie)</span>
            <span class="input-icon">
              <svg
                version="1.1"
                id="barcode-icon"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="439.281px"
                height="439.281px"
                viewBox="0 0 439.281 439.281"
                style="enable-background:new 0 0 439.281 439.281;"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M31,58.283H5c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h26c2.762,0,5-2.238,5-5V63.283
                                  C36,60.521,33.762,58.283,31,58.283z"
                    />
                    <path
                      d="M76.999,58.283h-22c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h22c2.762,0,5-2.238,5-5V63.283
                                  C81.999,60.521,79.761,58.283,76.999,58.283z"
                    />
                    <path
                      d="M113.999,58.283h-11c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h11c2.762,0,5-2.238,5-5V63.283
                                  C118.999,60.521,116.761,58.283,113.999,58.283z"
                    />
                    <path
                      d="M147.812,58.283h-12.625c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h12.625c2.762,0,5-2.238,5-5V63.283
                                  C152.812,60.521,150.574,58.283,147.812,58.283z"
                    />
                    <path
                      d="M199.812,58.283h-20.625c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h20.625c2.762,0,5-2.238,5-5V63.283
                                  C204.812,60.521,202.574,58.283,199.812,58.283z"
                    />
                    <path
                      d="M233.499,58.283h-10.313c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h10.313c2.763,0,5-2.238,5-5V63.283
                                  C238.499,60.521,236.262,58.283,233.499,58.283z"
                    />
                    <path
                      d="M269.32,58.283h-12.807c-2.763,0-5,2.239-5,5v312.715c0,2.762,2.237,5,5,5h12.807c2.762,0,5-2.238,5-5V63.283
                                  C274.32,60.521,272.082,58.283,269.32,58.283z"
                    />
                    <path
                      d="M321.5,58.283h-10.314c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5H321.5c2.762,0,5-2.238,5-5V63.283
                                  C326.5,60.521,324.262,58.283,321.5,58.283z"
                    />
                    <path
                      d="M377,58.283h-21.461c-2.764,0-5,2.239-5,5v312.715c0,2.762,2.236,5,5,5H377c2.762,0,5-2.238,5-5V63.283
                                  C382,60.521,379.762,58.283,377,58.283z"
                    />
                    <path
                      d="M434.281,58.283h-30.742c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h30.742c2.764,0,5-2.238,5-5V63.283
                                  C439.281,60.521,437.043,58.283,434.281,58.283z"
                    />
                  </g>
                </g>
              </svg>
            </span>
            <div v-if="this.errors.sn" class="error-message">{{this.errors.sn}}</div>
          </div>

          <div class="form-group">
            <!-- <input
              type="text"
              class="form-control"
              v-model="client.purchased_at"
              id="date"
              name="date"
              maxlength="10"
              required
            /> -->
            <v-date-picker v-model="client.purchased_at" :popover="{visibility: 'click' }" class="form-control date-pick" id="date" name="date" maxlength="10" required/>
            <span class="floating-label">Data achiziției</span>
            <span class="input-icon">
              <svg
                version="1.1"
                id="calendar-icon"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 60 60"
                style="enable-background:new 0 0 60 60;"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M57,4h-7V1c0-0.553-0.447-1-1-1h-7c-0.553,0-1,0.447-1,1v3H19V1c0-0.553-0.447-1-1-1h-7c-0.553,0-1,0.447-1,1v3H3
                                  C2.447,4,2,4.447,2,5v11v43c0,0.553,0.447,1,1,1h54c0.553,0,1-0.447,1-1V16V5C58,4.447,57.553,4,57,4z M43,2h5v3v3h-5V5V2z M12,2h5
                                  v3v3h-5V5V2z M4,6h6v3c0,0.553,0.447,1,1,1h7c0.553,0,1-0.447,1-1V6h22v3c0,0.553,0.447,1,1,1h7c0.553,0,1-0.447,1-1V6h6v9H4V6z
                                  M4,58V17h52v41H4z"
                  />
                  <path
                    d="M38,23h-7h-2h-7h-2h-9v9v2v7v2v9h9h2h7h2h7h2h9v-9v-2v-7v-2v-9h-9H38z M31,25h7v7h-7V25z M38,41h-7v-7h7V41z M22,34h7v7h-7
                                  V34z M22,25h7v7h-7V25z M13,25h7v7h-7V25z M13,34h7v7h-7V34z M20,50h-7v-7h7V50z M29,50h-7v-7h7V50z M38,50h-7v-7h7V50z M47,50h-7
                                  v-7h7V50z M47,41h-7v-7h7V41z M47,25v7h-7v-7H47z"
                  />
                </g>
              </svg>
            </span>
            <div v-if="this.errors.purchased_at" class="error-message">{{this.errors.purchased_at}}</div>
          </div>
          <div class="form-group">
            <input id="terms" type="checkbox" v-model="client.terms" required />
            <label class="terms" for="terms">
              <p>
                Am citit <a href="#">regulamentul campaniei</a> promoționale, sunt de acord cu clauzele și doresc să particip sub condițiile puse.
              </p>
              <div v-if="this.errors.terms" class="error-message">{{this.errors.terms}}</div>
            </label>
          </div>
          <a @click="postRegister" class="register-btn">înregistrează-te</a>
          <div v-if="this.success" class="success-message">{{this.success}}</div>
        </form>
      </div>

      <!-- <img class="registration__character" src="images/unlocker1.jpg" alt="Lenovo the Healer"> -->
    </div>
  </div>
</template>


<script>
export default {
  data: function() {
    return {
      client: {
        firstname: "",
        lastname: "",
        email: "",
        sn: "",
        purchased_at: "",
        terms: ""
      },
      errors: {
        firstname: "",
        lastname: "",
        email: "",
        sn: "",
        purchased_at: "",
        terms: ""
      },
      success: "",
      valid: 1
    };
  },
  methods: {
    postRegister: function() {
      this.success = "";
      this.valid = 1;
      this.errors = {
        firstname: "",
        lastname: "",
        email: "",
        sn: "",
        purchased_at: "",
        terms: ""
      };

      Object.keys(this.errors).forEach(key => {
        if (!this.client[key]) {
          this.errors[key] = "Campul este obligatoriu.";
          this.valid = 0;
        }
      });
      if (this.valid === 1) {
        axios
          .post("/api/register-product", { attempt: this.client })
          .then(response => {
            this.errors = {};
            this.success = "Felicitări, te-ai inscris in Legion of Heroes!";
            this.client = {};
          })
          .catch(error => {
            Object.keys(this.errors).forEach(key => {
              if (error.response.data.errors["attempt." + key]) {
                this.errors[key] =
                  error.response.data.errors["attempt." + key][0];
              }
            });
          });
      }
    }
  }
};
</script>
