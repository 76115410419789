<template>
    <nav class="nav">
      <img src="images/legion-logo-big.png" alt="Lenovo Logo" class="nav__logo">

      <div class="nav__menu">
        <a href="#header" title="Home">Home</a>
        <a href="#about-modal" class="popup-modal" title="Despre Campanie">Despre</a>
        <a href="#registration" title="Înregistrare">Unlock</a>
        <a href="#upgrade" title="Heal" class="nav-heal-open">Heal</a>
        <a href="#upgrade" title="Upgrader" class="nav-upgrade-open">Upgrade</a>
        <!-- <a href="#specs" title="Produse">Produse</a> -->
        <a href="#intrebari-fracvente" title="Întrebări frecvente">Întrebări frecvente</a>
        <a class="popup-modal" href="#refund-form-modal" title="Formular Rambursare">Contact</a>
      </div>
      
      <div class="hamburger">
        <div class="hamburger__line l1"></div>
        <div class="hamburger__line l2"></div>
        <div class="hamburger__line l3"></div>
      </div>
    </nav>
</template>



<script>

  export default {
      
  }

</script>
