<template>
    <div id="refund-form-modal" class="white-popup-block mfp-hide refund">
        <p><a @click="closeModal" class="popup-modal-dismiss close thick"></a></p>
        <div class="refund__title">
            <h1>Contact</h1>
            <p>Lenovo susține gamingul și competiția la fiecare click. Așa că dacă ai orice fel de întrebări despre produsele Legion sau despre Campania Legion of Heroes, trimite-ne un mesaj pe Pagina de Facebook Lenovo sau pe email la contact[@] legionofheroes.ro. Promitem să fim aici pentru tine!</p>
            <p>Mai mult, dacă laptopul tău înregistrat pe Legion Of Heroes nu a fost reparat în maxim 5 zile lucrătoare sau dacă nu ești mulțumit, poți să soliciți rambursarea pentru produsul cumpărat.</p>
        </div>
        <div class="container">
            <div class="refund-form">
                <div class="refund-form__left">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="client.firstname" id="name" name="nume"
                               required>
                        <span class="floating-label">Nume</span>
                        <span v-if="this.errors.firstname" class="error-message"> {{this.errors.firstname}}</span>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="client.lastname" id="prenume-refund"
                               name="prenume" required>
                        <span class="floating-label">Prenume</span>
                        <span v-if="this.errors.lastname" class="error-message"> {{this.errors.lastname}}</span>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="client.email" id="email-refund" name="email"
                               required>
                        <span class="floating-label">Adresă e-mail</span>
                        <span v-if="this.errors.email" class="error-message"> {{this.errors.email}}</span>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="client.phone" id="telefon-refund"
                               name="telefon" required>
                        <span class="floating-label">Telefon</span>
                        <span v-if="this.errors.phone" class="error-message"> {{this.errors.phone}}</span>
                    </div>
                </div>
                <div class="refund-form__right">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="client.sn" id="sn-refund" name="serie"
                               required>
                        <span class="floating-label">Serie produs</span>
                        <span v-if="this.errors.sn" class="error-message"> {{this.errors.sn}}</span>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="client.iban" id="iban-refund" name="iban"
                               required>
                        <span class="floating-label">Număr cont bancar</span>
                        <span v-if="this.errors.iban" class="error-message"> {{this.errors.iban}}</span>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="client.depanero" id="depanero-refund"
                               name="depanero" required>
                        <span class="floating-label">Dovada Preluării</span>
                        <span v-if="this.errors.depanero" class="error-message"> {{this.errors.depanero}}</span>
                    </div>
                    <div class="form-group">

                        <label class="return-label">Informații Factură</label>
                        <div class="form-group-invoice">
                            <div class="form-group-invoice__date relative">
                                <!-- <input type="text" class="form-control date-valid date-picker" id="date"
                                       v-model="client.invoice.date" name="date" required> -->
                                <v-date-picker v-model="client.invoice.date" :popover="{visibility: 'click' }" />
                                <span class="floating-label">Data</span>
                            </div>
                            <div class="form-group-invoice__sn relative">
                                <input type="text" class="form-control" id="invoice-sn" v-model="client.invoice.serial"
                                       name="invoice-sn" maxlength="64" required>
                                <span class="floating-label">Serie</span>
                                <div v-if="errors.invoice.serial" class='error-message'>{{this.errors.invoice.serial}}
                                </div>
                            </div>
                            <div class="form-group-invoice__no relative">
                                <input type="text" class="form-control" id="invoice-no" v-model="client.invoice.number"
                                       name="invoice-no" maxlength="64" required>
                                <span class="floating-label">Număr</span>
                                <div v-if="errors.invoice.number" class='error-message'>{{this.errors.invoice.number}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-full">
                <div class="form-group">
                    <label class="terms">
                        <input v-model="client.terms" type="checkbox" id="terms-refund" name="terms" required>
                        <p>Am citit <a href="#">regulamentul campaniei</a> promoționale, sunt de acord cu clauzele și doresc să particip sub condițiile puse.</p>
                        <span v-if="this.errors.terms" class="error-message"> {{this.errors.terms}}</span>
                    </label>
                </div>
                <div class="regbtnwrp">
                    <a @click="postRegister" class="register-btn">înregistrează-te</a>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

    export default {
        data: function () {
            return {
                client: {
                    fullname: "",
                    lastname: "",
                    email: "",


                    terms: '',
                    sn: "",
                    iban: "",
                    depanero: "",
                    phone: "",
                    invoice: {serial: "", number: "", date: ""}
                },
                errors: {
                    firstname: '',
                    lastname: '',
                    email: '',

                    terms: '',
                    sn: "",
                    iban: "",
                    depanero: "",
                    phone: "",
                    invoice: {serial: "", number: "", date: ""}

                },
                success: ""
            }
        },
        methods: {
            closeModal: function () {
                this.errors = {
                    firstname: '',
                    lastname: '',
                    email: '',

                    terms: '',
                    sn: "",
                    iban: "",
                    depanero: "",
                    phone: "",
                    invoice: {serial: "", number: "", date: ""}

                };
            },
            postRegister: function () {
                this.success = "";
                this.valid = 1;
                this.errors = {
                    firstname: '',
                    lastname: '',
                    email: '',

                    terms: '',
                    sn: "",
                    iban: "",
                    depanero: "",
                    phone: "",
                    invoice: {serial: "", number: "", date: ""}

                };

                Object.keys(this.errors).forEach(key => {
                    if (!this.client[key]) {
                        this.errors[key] = "Campul este obligatoriu.";
                        this.valid = 0;
                    }
                });
                if (this.valid === 1) {
                    axios.post('/api/register-refund', {attempt: this.client}).then(response => {
                        this.errors = {};
                        this.success = "Returul a fost inregistrat!";
                        this.client = {};
                    }).catch(error => {

                        Object.keys(this.errors).forEach(key => {
                            if (error.response.data.errors['attempt.' + key]) {
                                this.errors[key] = error.response.data.errors['attempt.' + key][0];

                            }
                        })

                    })
                }
            }

        }

    }

</script>
