<template>
    <div id="specs" class="specs">
      <div class="specs-slider">
        <!-- Specs Y540 -->
        <div class="specs-slider__slide">
          <div class="specs-slider__title">
            <div class="specs-slider__title--y">
              <img src="images/legion-logo-big.png" alt="Lenovo Legion Logo">
              <h1 class="specs-slider__slide--title">Y540</h1>
            </div>
            <img class="l-v-logo" src="images/lenovo-v-logo.jpg" alt="Lenovo Logo">
          </div>
          <img class="specs-slider__slide--img" src="images/y540-1.png" alt="Lenovo Legion Y530">
          <img class="specs-slider__slide--specs" src="images/intel-box.png" alt="Lenovo Legion Intel Inside">
          <div class="specs-slider__slide--cta-wrapper">
            <a class="popup-modal specs-slider__slide--cta noscroll--js" href="#specs-modal-y540">Vezi specificații</a>
            <a class="popup-modal specs-slider__slide--cta noscroll--js" href="#gallery-modal-y540">Vezi galerie</a>
          </div>
          
          <div id="specs-modal-y540" class="white-popup-block mfp-hide specs-modal">
            <div class="specs-modal__title">
              <h1>Specificații</h1>
              <p><a class="popup-modal-dismiss close thick specs-modal-close"></a></p>
            </div>
            
            <!-- Specs Modal Content -->
            <div class="slide__panel--specs">
              <div class="slide__panel--specs--col">
                <p>
                  <strong class="specs-title">Procesor</strong> <br>
                  <br>
                  <span>9th Generation Intel® Core™ i7-9750H</span><br>
                  <span>9th Generation Intel® Core™ i5-9300H Processor</span>
                </p>
                <br>
                <br>
              </div>

              <div class="slide__panel--specs--col">
                <p>
                  <strong class="specs-title">Grafică</strong> <br>
                  <br>
                  <span>NVIDIA® GeForce RTX™ 2060</span><br>
                  <span>NVIDIA® GeForce® GTX 1660Ti</span><br>
                  <span>NVIDIA® GeForce® GTX 1650</span><br>
                </p>
                <br>
                <br>
              </div>

              <div class="slide__panel--specs--col">
                <p>
                  <strong class="specs-title">Display</strong> <br>
                  <br>
                  <span> 17.3” FHD (300 nits / NTSC 72% / IPS / AG / 144 Hz)</span><br>
                  <span>17.3” FHD (300 nits / NTSC 72% /IPS / AG / 60 Hz)</span><br>
                  <span>15.6” FHD (1920 x 1080) IPS (144 Hz / 72% Color Gamut / 300 nits)</span><br>
                  <span>15.6” FHD (1920 x 1080) IPS (60 Hz / 72% Color Gamut / 300 nits)</span><br>
                  <span>15.6” FHD (1920 x 1080) IPS (60 Hz / 45% Color Gamut / 250 nits)</span><br>
                </p>
                <br>
                <br>
              </div>

              <div class="slide__panel--specs--col">
                <p>
                  <strong class="specs-title">Memorie</strong> <br>
                  <br>
                  <span>8 GB / 12 GB / 16 GB / 32 GB DDR4 2666 MHz</span><br>
                  <span>Intel Optane</span><br>
                </p>
              </div>
              
            </div>

            <div class="slide__panel--links">
              <p>Vezi specificatii complete (model 15") <a href="https://www.lenovo.com/ro/ro/laptops/legion-laptops/legion-y-series/Lenovo-Legion-Y540-15/p/88GMY501214">aici</a></p>
              <p>Vezi specificatii complete (model 17") <a href="https://www.lenovo.com/ro/ro/laptops/legion-laptops/legion-y-series/Lenovo-Legion-Y540-17IRH/p/88GMY501216">aici</a></p>
            </div>
          </div>

          <!-- Gallery Modal start -->
          <div id="gallery-modal-y540" class="white-popup-block mfp-hide specs-modal">
            <div class="specs-modal__title">
              <h1>Galerie</h1>
              <p><a class="popup-modal-dismiss close thick specs-modal-close"></a></p>
            </div>
            
            <div class="slide__panel--gallery">
              <div class="slide__panel--gallery--wrap">
                <div class="popup-gallery-1">
                  <a class="slide__panel--img glightbox" data-gallery="gallery1" href="images/y540/600x600-1.png">
                    <img class="slide__panel--img" src="images/y540/600x600-1.png" alt="">
                  </a>
                  <a class="slide__panel--img glightbox" data-gallery="gallery1" href="images/y540/600x600-2.png">
                    <img class="slide__panel--img" src="images/y540/600x600-2.png" alt="">
                  </a>
                  <a class="slide__panel--img glightbox" data-gallery="gallery1" href="images/y540/600x600-3.png">
                    <img class="slide__panel--img" src="images/y540/600x600-3.png" alt="">
                  </a>
                  <a class="slide__panel--img glightbox" data-gallery="gallery1" href="images/LEGION_Y540_360_ANIMATION_15INCH_NOTEBOOK.mp4">
                    <img class="icon-360" src="images/360-degrees.png" alt="Lenovo Legion 360 view">
                    <img class="slide__panel--img" src="images/y540/600x600-1.png" alt="">
                  </a>
                </div>
              </div>
            </div>

          </div>
          <!-- Gallery Modal end -->
        </div>






      <!-- Specs Y700 -->
        <div class="specs-slider__slide">
          <div class="specs-slider__title">
            <div class="specs-slider__title--y">
              <img src="images/legion-logo-big.png" alt="Lenovo Legion Logo">
              <h1 class="specs-slider__slide--title">Y7000</h1>
            </div>
            <img class="l-v-logo" src="images/lenovo-v-logo.jpg" alt="Lenovo Logo">
          </div>
          <img class="specs-slider__slide--img" src="images/y700/y700.png" alt="Lenovo Legion Y7000">
          <img class="specs-slider__slide--specs" src="images/intel-box.png" alt="Lenovo Legion Intel Inside">
          <div class="specs-slider__slide--cta-wrapper">
            <a class="popup-modal specs-slider__slide--cta noscroll--js" href="#specs-modal-y700">Vezi specificații</a>
            <a class="popup-modal specs-slider__slide--cta noscroll--js" href="#gallery-modal-y700">Vezi galerie</a>
          </div>
          
          <div id="specs-modal-y700" class="white-popup-block mfp-hide specs-modal">
            <div class="specs-modal__title">
              <h1>Specificații</h1>
              <p><a class="popup-modal-dismiss close thick specs-modal-close"></a></p>
            </div>
            
            <!-- Specs Modal Content -->
            <div class="slide__panel--specs">
              <div class="slide__panel--specs--col">
                <p>
                  <strong class="specs-title">Procesor</strong> <br>
                  <br>
                  <span>9th Generation Intel® Core™ i7-9750H</span><br>
                  <span>9th Generation Intel® Core™ i5-9300H Processor</span>
                </p>
                <br>
                <br>
              </div>

              <div class="slide__panel--specs--col">
                <p>
                  <strong class="specs-title">Grafică</strong> <br>
                  <br>
                  <span>NVIDIA® GeForce RTX™ 2060</span><br>
                  <span>NVIDIA® GeForce® GTX 1660Ti</span><br>
                  <span>NVIDIA® GeForce® GTX 1650</span><br>
                </p>
                <br>
                <br>
              </div>

              <div class="slide__panel--specs--col">
                <p>
                  <strong class="specs-title">Display</strong> <br>
                  <br>
                  <span>15.6” FHD (1920 x 1080) IPS (144 Hz / 72% Color Gamut / 300 nits)</span><br>
                  <span>15.6” FHD (1920 x 1080) IPS (60 Hz / 72% Color Gamut / 300 nits)</span><br>
                  <span>15.6” FHD (1920 x 1080) IPS (60 Hz / 45% Color Gamut / 250 nits)</span><br>
                </p>
                <br>
                <br>
              </div>

              <div class="slide__panel--specs--col">
                <p>
                  <strong class="specs-title">Memorie</strong> <br>
                  <br>
                  <span>8 GB / 12 GB / 16 GB / 32 GB DDR4 2666 MHz</span><br>
                </p>
              </div>
              
            </div>

            <div class="slide__panel--links">
              <p>Vezi specificatii complete (model Y7000) <a href="https://www.lenovo.com/ro/ro/laptops/legion-laptops/legion-y-series/Lenovo-Legion-Y7000-2019/p/88GMY501215">aici</a></p>
              <p>Vezi specificatii complete (model 7000P) <a href="https://www.lenovo.com/ro/ro/laptops/legion-laptops/legion-y-series/Lenovo-Legion-Y7000P-2019/p/88GMY501217">aici</a></p>
            </div>
          </div>

          <!-- Gallery Modal start -->
          <div id="gallery-modal-y700" class="white-popup-block mfp-hide specs-modal">
            <div class="specs-modal__title">
              <h1>Galerie</h1>
              <p><a class="popup-modal-dismiss close thick specs-modal-close"></a></p>
            </div>
            
            <div class="slide__panel--gallery">
              <div class="slide__panel--gallery--wrap">
                <div class="popup-gallery-2">
                  <a class="slide__panel--img glightbox" data-gallery="gallery2" href="images/y700/600x600-1.png">
                    <img class="slide__panel--img" src="images/y700/600x600-1.png" alt="">
                  </a>
                  <a class="slide__panel--img glightbox" data-gallery="gallery2" href="images/y700/600x600-2.png">
                    <img class="slide__panel--img" src="images/y700/600x600-2.png" alt="">
                  </a>
                  <a class="slide__panel--img glightbox" data-gallery="gallery2" href="images/y700/600x600-3.png">
                    <img class="slide__panel--img" src="images/y700/600x600-3.png" alt="">
                  </a>
                  <a class="slide__panel--img glightbox" data-gallery="gallery2" href="images/y700/600x600-4.png">
                    <img class="slide__panel--img" src="images/y700/600x600-3.png" alt="">
                  </a>
                  <a class="slide__panel--img glightbox" data-gallery="gallery2" href="images/LEGION_Y740_15INCH_360SPIN_V01.mp4">
                    <img class="icon-360" src="images/360-degrees.png" alt="Lenovo Legion 360 view">
                    <img class="slide__panel--img" src="images/y540/600x600-1.png" alt="">
                  </a>
                </div>
              </div>
            </div>

          </div>
          <!-- Gallery Modal end -->
        </div>







        <!-- Specs Y740 -->
        <div class="specs-slider__slide">
          <div class="specs-slider__title">
            <div class="specs-slider__title--y">
              <img src="images/legion-logo-big.png" alt="Lenovo Legion Logo">
              <h1 class="specs-slider__slide--title">Y740</h1>
            </div>
            <img class="l-v-logo" src="images/lenovo-v-logo.jpg" alt="Lenovo Logo">
          </div>
          <img class="specs-slider__slide--img" src="images/y740/y740.png" alt="Lenovo Legion Y740">
          <img class="specs-slider__slide--specs" src="images/intel-box.png" alt="Lenovo Legion Intel Inside">
          <div class="specs-slider__slide--cta-wrapper">
            <a class="popup-modal specs-slider__slide--cta noscroll--js" href="#specs-modal-y740">Vezi specificații</a>
            <a class="popup-modal specs-slider__slide--cta noscroll--js" href="#gallery-modal-y740">Vezi galerie</a>
          </div>
          
          <div id="specs-modal-y740" class="white-popup-block mfp-hide specs-modal">
            <div class="specs-modal__title">
              <h1>Specificații</h1>
              <p><a class="popup-modal-dismiss close thick specs-modal-close"></a></p>
            </div>
            
            <!-- Specs Modal Content -->
            <div class="slide__panel--specs">
              <div class="slide__panel--specs--col">
                <p>
                  <strong class="specs-title">Procesor</strong> <br>
                  <br>
                  <span>9th Generation Intel® Core™ i7-9750H</span><br>
                  <span>9th Generation Intel® Core™ i5-9300H Processor</span>
                </p>
                <br>
                <br>
              </div>

              <div class="slide__panel--specs--col">
                <p>
                  <strong class="specs-title">Grafică</strong> <br>
                  <br>
                  <span>NVIDIA® GeForce RTX™ 2060</span><br>
                  <span>NVIDIA® GeForce® GTX 1660Ti</span><br>
                  <span>NVIDIA® GeForce® GTX 1650</span><br>
                </p>
                <br>
                <br>
              </div>

              <div class="slide__panel--specs--col">
                <p>
                  <strong class="specs-title">Display</strong> <br>
                  <br>
                  <span>17.3" FHD (1920 x 1080), 72% color gamut, Dolby Vision-enabled, 144 Hz, până la 500 nits, opțional NVIDIA® G-SYNC</span><br>
                  <span>15.6" FHD (1920 x 1080), 72% gamut, NVIDIA® G-SYNC, Dolby Vision-enabled, 144 Hz, până la 500 niți</span><br>
                </p>
                <br>
                <br>
              </div>

              <div class="slide__panel--specs--col">
                <p>
                  <strong class="specs-title">Memorie</strong> <br>
                  <br>
                  <span>8 GB / 12 GB / 16 GB / 32 GB DDR4 2666 MHz</span><br>
                  <span>Intel Optane</span><br>
                </p>
              </div>
              
            </div>

            <div class="slide__panel--links">
              <p>Vezi specificatii complete (model 15") <a href="https://www.lenovo.com/ro/ro/laptops/legion-laptops/legion-y-series/Lenovo-Legion-Y740-15/p/88GMY701059">aici</a></p>
              <p>Vezi specificatii complete (model 17") <a href="https://www.lenovo.com/ro/ro/laptops/legion-laptops/legion-y-series/Lenovo-Legion-Y740-17/p/88GMY701062">aici</a></p>
            </div>
          </div>

          <!-- Gallery Modal start -->
          <div id="gallery-modal-y740" class="white-popup-block mfp-hide specs-modal">
            <div class="specs-modal__title">
              <h1>Galerie</h1>
              <p><a class="popup-modal-dismiss close thick specs-modal-close" ></a></p>
            </div>
            
            <div class="slide__panel--gallery">
              <div class="slide__panel--gallery--wrap">
                <div class="popup-gallery-3">
                  <a class="slide__panel--img glightbox" data-gallery="gallery3" href="images/y740/600x600-1.png">
                    <img class="slide__panel--img" src="images/y740/600x600-1.png" alt="">
                  </a>
                  <a class="slide__panel--img glightbox" data-gallery="gallery3" href="images/y740/600x600-2.png">
                    <img class="slide__panel--img" src="images/y740/600x600-2.png" alt="">
                  </a>
                  <a class="slide__panel--img glightbox" data-gallery="gallery3" href="images/y740/600x600-3.png">
                    <img class="slide__panel--img" src="images/y740/600x600-3.png" alt="">
                  </a>
                  <a class="slide__panel--img glightbox" data-gallery="gallery3" href="images/y740/600x600-4.png">
                    <img class="slide__panel--img" src="images/y740/600x600-3.png" alt="">
                  </a>
                  <a class="slide__panel--img glightbox" data-gallery="gallery3" href="images/LEGION_Y740_15INCH_360SPIN_V01.mp4">
                    <img class="icon-360" src="images/360-degrees.png" alt="Lenovo Legion 360 view">
                    <img class="slide__panel--img" src="images/y540/600x600-1.png" alt="">
                  </a>
                </div>
              </div>
            </div>

          </div>
          <!-- Gallery Modal end -->
        </div>


      </div>
    </div>
    
</template>



<script>

  export default {
      
  }

</script>
