<template>
    <div id="about-modal" class="about-popup-block mfp-hide about-modal">
        <p><a class="popup-modal-dismiss close thick"></a></p>

        <div class="container container--center">

            <div class="about-modal__title">
                Înscrie-te în Legion of Heroes și ai o mulţime de beneficii!
                <img class="about-modal__img" src="images/lenovo-v-logo.jpg" alt="Lenovo Legion Logo">
            </div>
        
            <div class="about-modal__content">
                <div class="about-modal__content--info">
                    Știm că puțin ajutor nu strică niciodată, mai ales într-o lume a gaming-ului pe bune, unde competiția e la fiecare click. Așa că am creat Legion Heroes Pack, un pachet de bonusuri creat special pentru gameri. Tot ce ai de făcut este să înregistrezi laptopul tău Legion Y540, Legion Y7000 sau Legion Y740 și îi ai alături pe cei 3 aliați de încredere pe care o să te poți baza garantat!
                </div>

                <div class="about-modal__cols">
                    <div class="about-modal__cols--wrp">
                        <img class="about-modal__cols--icon" src="images/text-heal.png" alt="Lenovo Legion The Healer">
                        <div class="about-modal__cols--content about-modal--text-green">beneficiezi de same-day pickup și service prioritar garantat în maxim 5 zile lucrătoare</div>
                    </div>

                    <div class="about-modal__cols--wrp">
                        <img class="about-modal__cols--icon" src="images/text-unlocker.png" alt="Lenovo Legion The Unlocker">
                        <div class="about-modal__cols--content about-modal--text-yellow">îţi aduce 25% discount la orice accesoriu Lenovo Legion </div>
                    </div>

                    <div class="about-modal__cols--wrp">
                        <img class="about-modal__cols--icon" src="images/text-upgr.png" alt="Lenovo Legion The Upgrader">
                        <div class="about-modal__cols--content about-modal--text-blue">îţi oferă upgrade cu manoperă gratuită, în maxim 5 zile lucrătoare.</div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
    export default {
    }
</script>
