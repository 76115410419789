<template>
  <div id="upgrade" class="upgrade">
    <div class="upgrade__tabs">
      <!-- LEFT SIDE -->
      <div class="upgrade__tabs--left">

        <div class="upgrade__tabs--left--container">
          <div class="polygon-left"></div>
        </div>

        <div class="upgrade__tabs--left__cta">
          <img class="upgrade__tabs--left--character--m" src="images/healer-icon-m.png" alt="Lenovo Legion the Healer Character">
          <h1>THE HEALER</h1>
        </div>

        <p class="upgrade__tabs--info--d isDesktop">Dacă laptopul tău Legion are nevoie de Heal, completează formularul și ai parte de service prioritar garantat!</p>

        <img class="upgrade__tabs--left--character" src="images/healer-big-d.png" alt="Lenovo Legion the Healer Character">

      </div>

      <!-- CENTER SIDE -->
      <div class="upgrade__tabs--center">
        <!-- HEALER FORM -->
        <healer-form ></healer-form>
        <!-- UPGRADER FORM -->
        <upgrader-form></upgrader-form>
      </div>

      <!-- RIGHT SIDE -->
      <div class="upgrade__tabs--right">
        <div class="upgrade__tabs--right--container">
          <div class="polygon-right"></div>
        </div>

        <div class="upgrade__tabs--right__cta">
          <img class="upgrade__tabs--right--character--m" src="images/upgrader-icon-m.png" alt="Lenovo Legion the Upgrader Character">
          <h1>THE UPGRADER</h1>
        </div>

        <p class="upgrade__tabs--info--d isDesktop">Dacă laptopul tău Legion are nevoie de Upgrade, completează formularul și ai parte de upgrade garantat!</p>

        <img class="upgrade__tabs--right--character" src="images/upgrader-big-d.png" alt="Lenovo Legion the Upgrader Character">

      </div>
    </div>
  </div>
</template>


<script>

    export default {
        data: function () {
            return {
                client: {
                    firstname: '',
                    lastname: '',
                    email: '',
                    address: '',
                    corporate: '',
                    hardware: '',
                    company: '',
                    pickup: '',
                    issue: '',
                    accessories: '',
                    sn: '',
                    invoice: {
                        serial: '',
                        number: '',
                        date: ''
                    },
                    terms: ''
                },
                errors: {
                    firstname: '',
                    lastname: '',
                    email: '',
                    address: '',
                    corporate: '',
                    hardware: '',
                    company: '',
                    pickup: '',
                    issue: '',
                    accessories: '',
                    sn: '',
                    invoice: {
                        serial: '',
                        number: '',
                        date: ''
                    },
                    terms: ''
                },
                success: "",
                valid: 1
            }
        },
    }

</script>
