<template>
<div id="intrebari-fracvente" class="faq">
  <h1>Întrebări Frecvente</h1>

  <dl class="faq__accordion">
    <div class="faq__accordion--element">
      <dt><a href="">1. CUM POT PARTICIPA LA CAMPANIA PROMOȚIONALĂ?</a></dt>
      <dd>Pentru a participa la promoție trebuie să înregistrați achiziția produsului promoțional (Lenovo Legion Y540, Y7000, Y740) pe pagina www.heroeslegion.ro în termen de 31 de zile de la data achiziției produsului promoțional, dar nu mai târziu de 31 Ianuarie 2019. Înregistrarea achiziției necesită furnizarea următoarelor informații: adresa de e-mail, indicarea datei de achiziție, modelul și numărul seriei produsului promoțional, dar și anexarea în formă electronică, lizibilă, a scan-ului sau o fotografie clară a dovezii achiziției.</dd>
    </div>

    <div class="faq__accordion--element">
      <dt><a href="">2. CINE POATE PARTICIPA LA CAMPANIA PROMOȚIONALĂ?</a></dt>
      <dd>Participant la Promoție poate fi orice persoană juridică sau fizică cu capacitate juridică deplină.</dd>
    </div>

    <div class="faq__accordion--element">
      <dt><a href="">3. CARE SUNT BENEFICIILE PARTICIPĂRII LA CAMPANIA PROMOȚIONALĂ?</a></dt>
      <dd>Produsele care fac parte din Promoție vor avea perioada de reparație/ schimbare în baza garanției scurtată până la 5 zile lucrătoare, în sistem door-to-door (ridicare & livrare la domiciliu). </dd>
    </div>

    <div class="faq__accordion--element">
      <dt><a href="">4. CE PRODUSE FAC PARTE DIN CAMPANIA PROMOȚIONALĂ?</a></dt>
      <dd>În promoție sunt incluse toate produsele Lenovo Legion (Lenovo Legion Y540, Y7000, Y740). Lista completă este disponibilă <a href="#regulament">în regulament</a></dd>
    </div>

    <div class="faq__accordion--element">
      <dt><a href="">5. UNDE ESTE AMPLASAT NUMĂRUL DE SERIE AL APARATULUI (SN)?</a></dt>
      <dd>Numărul de serie al aparatului (SN), codul EAN, poate fi găsit pe eticheta cutiei echipamentului achiziționat.</dd>
    </div>

    <div class="faq__accordion--element">
      <dt><a href="">6. CARE SUNT VÂNZĂTORII CARE PARTICIPĂ LA CAMPANIA PROMOȚIONALĂ?</a></dt>
      <dd>Promoția are loc pe teritoriul României, adică este inclusă orice achiziție a produselor promoționale de la entitățile care își au sediul pe teritoriul României.</dd>
    </div>

    <div class="faq__accordion--element">
      <dt><a href="">7. CE SE ÎNTÂMPLĂ DACĂ PRODUSUL MEU NU ESTE REPARAT ÎN TERMEN DE 5 ZILE LUCRĂTOARE?</a></dt>
      <dd>În situația în care nu ne îndeplinim obligația de scurtare a perioadei de executare a garanției la 5 zile, puteți solicita rambursarea prețului produsului achiziționat. Condiția să beneficiați de această opțiune este să înregistrați în prealabil achiziția produsului promoțional în conformitate cu regulamentul campaniei, iar apoi să completați <a href="#refund-form-modal" class="popup-modal faq-link">formularul de rambursare</a></dd>
    </div>

    <div class="faq__accordion--element">
      <dt><a href="">8. CÂND ȘI CUM ÎMI PRIMESC BANII?</a></dt>
      <dd>Rambursarea prețului de achiziție va fi efectuată în termen de 30 de zile de la data primirii înștiințării, în contul bancar menționat în formularul de retur.</dd>
    </div>

    <div class="faq__accordion--element">
      <dt><a href="">9. DE CÂTE ORI POT BENEFICIA DE PROMOȚIE?</a></dt>
      <dd>Fiecare Participant poate beneficia de promoție de câte ori dorește și poate să înregistreze câte produse promoționale dorește. În schimb, rambursarea prețului poate avea loc o singură dată.</dd>
    </div>

    <div class="faq__accordion--element">
      <dt><a href="">10. CUM SE CALCULEAZĂ PERIOADA DE 5 ZILE LUCRĂTOARE?</a></dt>
      <dd>Perioada de 5 zile lucrătoare pentru efectuarea reparațiilor sau a schimbării în baza garanției va fi calculată de la data primirii produsului de către curierul indicat de către Lenovo, conform procedurii de service disponibile pe pagina https://www.lenovo.com/ro/ro/services-warranty/  până în ziua efectuării primei încercări de livrare a produsului promoțional după reparare sau înlocuire la adresa Participantului la Promoție. <br> 
      De exemplu:  În cazul în care produsul promoțional este preluat de curier luni, prima zi a termenului va fi marți, iar ultima zi va fi ziua de luni din săptămâna următoare.
      </dd>
    </div>

    <div class="faq__accordion--element">
      <dt><a href="">11. UNDE GĂSESC NUMĂRUL DE CONTACT AL SERVICE-ULUI?</a></dt>
      <dd>Numărul de contact al service-ului (REF) îl găsiți pe eticheta de transport pentru returul aparatului, etichetă primită la adresa de e-mail menționată în cererea de service.</dd>
    </div>

  </dl>
</div>



    
</template>



<script>

  export default {
      
  }

</script>
