<template>

<section id="footer" class="footer">
  <div class="container container-flex-row--2">
    <img class="footer__logo isDesktop" src="images/intel-logo.png" alt="Intel logo">
    <div class="footer__links">
      <a class="footer__link" href="#">Regulament</a>
      <a class="footer__link" href="#">Termeni și Condiții</a>
    </div>
    <div class="footer__logos isMobile">
      <img class="footer__logo" src="images/intel-logo.png" alt="Intel logo">
      <img class="footer__logo" src="images/legion-logo-new.png" alt="Legion logo">
    </div>
    <img class="footer__logo isDesktop" src="images/legion-logo-new.png" alt="Legion logo">
  </div>
</section>

</template>

<script>
  export default {
      
  }
</script>
