<template>
  <div class>
    <form id="upgrader" class="upgrader">
      <p class="upgrade__tabs--info isMobile">Dacă laptopul tău Legion are nevoie de Upgrade, completează formularul și ai parte de upgrade garantat!</p>
      <!-- Nume-->
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          id="name"
          v-model="client.fullname"
          name="nume"
          maxlength="125"
          required
        />
        <span class="floating-label">Numele întreg</span>
        <span class="input-icon">
          <svg
            version="1.1"
            id="name-icon"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background:new 0 0 512 512;"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148
                            C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962
                            c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216
                            h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40
                            c59.551,0,108,48.448,108,108S315.551,256,256,256z"
                />
              </g>
            </g>
          </svg>
        </span>
        <span v-if="errors.fullname" class="error-message">{{this.errors.fullname}}</span>
      </div>
      <!-- Nume-->

      <!-- Telefon-->
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          id="phone"
          v-model="client.phone"
          name="Telefon"
          maxlength="25"
          required
        />
        <span class="floating-label">Telefon</span>
        <span class="input-icon">
          <svg
            version="1.1"
            id="name-icon"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background:new 0 0 512 512;"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148
                            C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962
                            c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216
                            h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40
                            c59.551,0,108,48.448,108,108S315.551,256,256,256z"
                />
              </g>
            </g>
          </svg>
        </span>
        <span v-if="errors.phone" class="error-message">{{this.errors.phone}}</span>
      </div>
      <!-- Telefon-->

      <!-- Adresa email-->
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          id="email"
          v-model="client.email"
          name="email"
          maxlength="125"
          required
        />
        <span class="floating-label">Adresă e-mail</span>
        <span class="input-icon">
          <svg
            version="1.1"
            id="envelope-icon"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 483.3 483.3"
            style="enable-background:new 0 0 483.3 483.3;"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M424.3,57.75H59.1c-32.6,0-59.1,26.5-59.1,59.1v249.6c0,32.6,26.5,59.1,59.1,59.1h365.1c32.6,0,59.1-26.5,59.1-59.1
                            v-249.5C483.4,84.35,456.9,57.75,424.3,57.75z M456.4,366.45c0,17.7-14.4,32.1-32.1,32.1H59.1c-17.7,0-32.1-14.4-32.1-32.1v-249.5
                            c0-17.7,14.4-32.1,32.1-32.1h365.1c17.7,0,32.1,14.4,32.1,32.1v249.5H456.4z"
                />
                <path
                  d="M304.8,238.55l118.2-106c5.5-5,6-13.5,1-19.1c-5-5.5-13.5-6-19.1-1l-163,146.3l-31.8-28.4c-0.1-0.1-0.2-0.2-0.2-0.3
                            c-0.7-0.7-1.4-1.3-2.2-1.9L78.3,112.35c-5.6-5-14.1-4.5-19.1,1.1c-5,5.6-4.5,14.1,1.1,19.1l119.6,106.9L60.8,350.95
                            c-5.4,5.1-5.7,13.6-0.6,19.1c2.7,2.8,6.3,4.3,9.9,4.3c3.3,0,6.6-1.2,9.2-3.6l120.9-113.1l32.8,29.3c2.6,2.3,5.8,3.4,9,3.4
                            c3.2,0,6.5-1.2,9-3.5l33.7-30.2l120.2,114.2c2.6,2.5,6,3.7,9.3,3.7c3.6,0,7.1-1.4,9.8-4.2c5.1-5.4,4.9-14-0.5-19.1L304.8,238.55z"
                />
              </g>
            </g>
          </svg>
        </span>
        <div v-if="this.errors.email" class="error-message">{{this.errors.email}}</div>
      </div>
      <!-- Adresa email-->

      <!-- Adresa fizica-->
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          id="address"
          v-model="client.address"
          name="address"
          maxlength="200"
          required
        />
        <span class="floating-label">Adresa Domiciliu</span>
        <span class="input-icon">
          <svg
            version="1.1"
            id="envelope-icon"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 483.3 483.3"
            style="enable-background:new 0 0 483.3 483.3;"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M424.3,57.75H59.1c-32.6,0-59.1,26.5-59.1,59.1v249.6c0,32.6,26.5,59.1,59.1,59.1h365.1c32.6,0,59.1-26.5,59.1-59.1
                            v-249.5C483.4,84.35,456.9,57.75,424.3,57.75z M456.4,366.45c0,17.7-14.4,32.1-32.1,32.1H59.1c-17.7,0-32.1-14.4-32.1-32.1v-249.5
                            c0-17.7,14.4-32.1,32.1-32.1h365.1c17.7,0,32.1,14.4,32.1,32.1v249.5H456.4z"
                />
                <path
                  d="M304.8,238.55l118.2-106c5.5-5,6-13.5,1-19.1c-5-5.5-13.5-6-19.1-1l-163,146.3l-31.8-28.4c-0.1-0.1-0.2-0.2-0.2-0.3
                            c-0.7-0.7-1.4-1.3-2.2-1.9L78.3,112.35c-5.6-5-14.1-4.5-19.1,1.1c-5,5.6-4.5,14.1,1.1,19.1l119.6,106.9L60.8,350.95
                            c-5.4,5.1-5.7,13.6-0.6,19.1c2.7,2.8,6.3,4.3,9.9,4.3c3.3,0,6.6-1.2,9.2-3.6l120.9-113.1l32.8,29.3c2.6,2.3,5.8,3.4,9,3.4
                            c3.2,0,6.5-1.2,9-3.5l33.7-30.2l120.2,114.2c2.6,2.5,6,3.7,9.3,3.7c3.6,0,7.1-1.4,9.8-4.2c5.1-5.4,4.9-14-0.5-19.1L304.8,238.55z"
                />
              </g>
            </g>
          </svg>
        </span>
        <div v-if="errors.address" class="error-message">{{this.errors.address}}</div>
      </div>
      <!-- Adresa fizica-->

      <!-- Corporate sau PF-->
      <div class="form-group">
        <div class="select">
          <select @change="corporate" name="corporate" id="corporate">
            <option :selected="client.corporate == false" value="pf">Persoană Fizică</option>
            <option :selected="client.corporate == true" value="cp">Corporate</option>
          </select>
        </div>
      </div>
      <!-- Corporate sau PF -->

      <!-- Companie -->
      <div class="form-group" v-if="this.client.corporate === true">
        <input
          type="text"
          class="form-control"
          id="company"
          v-model="client.company"
          name="address"
          maxlength="64"
          required
        />
        <span class="floating-label">Companie</span>
        <span class="input-icon">
          <svg
            version="1.1"
            id="envelope-icon"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 483.3 483.3"
            style="enable-background:new 0 0 483.3 483.3;"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M424.3,57.75H59.1c-32.6,0-59.1,26.5-59.1,59.1v249.6c0,32.6,26.5,59.1,59.1,59.1h365.1c32.6,0,59.1-26.5,59.1-59.1
                            v-249.5C483.4,84.35,456.9,57.75,424.3,57.75z M456.4,366.45c0,17.7-14.4,32.1-32.1,32.1H59.1c-17.7,0-32.1-14.4-32.1-32.1v-249.5
                            c0-17.7,14.4-32.1,32.1-32.1h365.1c17.7,0,32.1,14.4,32.1,32.1v249.5H456.4z"
                />
                <path
                  d="M304.8,238.55l118.2-106c5.5-5,6-13.5,1-19.1c-5-5.5-13.5-6-19.1-1l-163,146.3l-31.8-28.4c-0.1-0.1-0.2-0.2-0.2-0.3
                            c-0.7-0.7-1.4-1.3-2.2-1.9L78.3,112.35c-5.6-5-14.1-4.5-19.1,1.1c-5,5.6-4.5,14.1,1.1,19.1l119.6,106.9L60.8,350.95
                            c-5.4,5.1-5.7,13.6-0.6,19.1c2.7,2.8,6.3,4.3,9.9,4.3c3.3,0,6.6-1.2,9.2-3.6l120.9-113.1l32.8,29.3c2.6,2.3,5.8,3.4,9,3.4
                            c3.2,0,6.5-1.2,9-3.5l33.7-30.2l120.2,114.2c2.6,2.5,6,3.7,9.3,3.7c3.6,0,7.1-1.4,9.8-4.2c5.1-5.4,4.9-14-0.5-19.1L304.8,238.55z"
                />
              </g>
            </g>
          </svg>
        </span>
        <div v-if="errors.company" class="error-message">{{this.errors.company}}</div>
      </div>
      <!-- Companie-->

      <!-- Pickup -->
      <div class="form-group">
        <!-- <input type="text" class="form-control date-valid date-picker" id="pickup"
                      v-model="client.pickup" name="date" maxlength="10"
        required >-->
        <v-date-picker
          v-model="client.pickup"
          class="form-control date-pick"
          :popover="{visibility: 'click' }"
        />
        <!-- <datepicker v-model="client.pickup" style="color:black;"  :format="customFormatter"></datepicker> -->

        <span class="floating-label placeholder-switch-black">Data pickup</span>
        <span class="input-icon">
          <svg
            version="1.1"
            id="calendar-icon"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            style="enable-background:new 0 0 60 60;"
            xml:space="preserve"
          >
            <g>
              <path
                d="M57,4h-7V1c0-0.553-0.447-1-1-1h-7c-0.553,0-1,0.447-1,1v3H19V1c0-0.553-0.447-1-1-1h-7c-0.553,0-1,0.447-1,1v3H3
                        C2.447,4,2,4.447,2,5v11v43c0,0.553,0.447,1,1,1h54c0.553,0,1-0.447,1-1V16V5C58,4.447,57.553,4,57,4z M43,2h5v3v3h-5V5V2z M12,2h5
                        v3v3h-5V5V2z M4,6h6v3c0,0.553,0.447,1,1,1h7c0.553,0,1-0.447,1-1V6h22v3c0,0.553,0.447,1,1,1h7c0.553,0,1-0.447,1-1V6h6v9H4V6z
                        M4,58V17h52v41H4z"
              />
              <path
                d="M38,23h-7h-2h-7h-2h-9v9v2v7v2v9h9h2h7h2h7h2h9v-9v-2v-7v-2v-9h-9H38z M31,25h7v7h-7V25z M38,41h-7v-7h7V41z M22,34h7v7h-7
                        V34z M22,25h7v7h-7V25z M13,25h7v7h-7V25z M13,34h7v7h-7V34z M20,50h-7v-7h7V50z M29,50h-7v-7h7V50z M38,50h-7v-7h7V50z M47,50h-7
                        v-7h7V50z M47,41h-7v-7h7V41z M47,25v7h-7v-7H47z"
              />
            </g>
          </svg>
        </span>
        <div v-if="errors.pickup" class="error-message">{{this.errors.pickup}}</div>
      </div>
      <!-- Pick Up -->

      <!-- Time of pickup-->
      <!-- <div class="form-group">
                <div class="select">
                    <select @change="pickup" name="pickup-time" id="pickup-time">
                        <option selected disabled>Interval Pickup</option>
                        <option value="814">Între 08:00 - 14:00</option>
                        <option value="1420">Între 14:00 - 20:00</option>
                    </select>
                </div>
      </div>-->

      <div class="form-group">
        <input
          type="time"
          name="pickup-time"
          class="form-control"
          id="pickup-time"
          v-model="client.pickup_time"
          required
        />
        <span class="floating-label">Oră pickup</span>
        <span class="input-icon">
          <svg
            version="1.1"
            id="calendar-icon"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            style="enable-background:new 0 0 60 60;"
            xml:space="preserve"
          >
            <g>
              <path
                d="M57,4h-7V1c0-0.553-0.447-1-1-1h-7c-0.553,0-1,0.447-1,1v3H19V1c0-0.553-0.447-1-1-1h-7c-0.553,0-1,0.447-1,1v3H3
                            C2.447,4,2,4.447,2,5v11v43c0,0.553,0.447,1,1,1h54c0.553,0,1-0.447,1-1V16V5C58,4.447,57.553,4,57,4z M43,2h5v3v3h-5V5V2z M12,2h5
                            v3v3h-5V5V2z M4,6h6v3c0,0.553,0.447,1,1,1h7c0.553,0,1-0.447,1-1V6h22v3c0,0.553,0.447,1,1,1h7c0.553,0,1-0.447,1-1V6h6v9H4V6z
                            M4,58V17h52v41H4z"
              />
              <path
                d="M38,23h-7h-2h-7h-2h-9v9v2v7v2v9h9h2h7h2h7h2h9v-9v-2v-7v-2v-9h-9H38z M31,25h7v7h-7V25z M38,41h-7v-7h7V41z M22,34h7v7h-7
                            V34z M22,25h7v7h-7V25z M13,25h7v7h-7V25z M13,34h7v7h-7V34z M20,50h-7v-7h7V50z M29,50h-7v-7h7V50z M38,50h-7v-7h7V50z M47,50h-7
                            v-7h7V50z M47,41h-7v-7h7V41z M47,25v7h-7v-7H47z"
              />
            </g>
          </svg>
        </span>
        <div v-if="errors.pickup_time" class="error-message">{{this.errors.pickup_time}}</div>
      </div>
      <!-- Time of pickup -->

      <!-- Accesorii -->
      <!-- <div class="form-group">
        <div class="select">
          <select name="accesories" id="accesories">
            <option selected disabled>Accesorii</option>
            <option value="Accesorii Lenovo Legion Y520">Accesorii Lenovo Legion Y520</option>
            <option value="Accesorii Lenovo Legion Y530">Accesorii Lenovo Legion Y530</option>
            <option value="Accesorii Lenovo Legion Y730">Accesorii Lenovo Legion Y730</option>
          </select>
        </div>
      </div> -->
      <!-- Accesorii-->

      <!-- Factura -->

      <div class="form-group">
        <label>Informații Factură</label>
        <div class="form-group-invoice">
          <div class="form-group-invoice__date relative">
            <!-- <input type="text" class="form-control date-valid date-picker" id="date"
                               v-model="client.invoice.date" name="date" maxlength="10"
            required>-->
            <v-date-picker v-model="client.invoice.date" :popover="{visibility: 'click' }" />
            <span class="floating-label placeholder-switch-black">Data</span>
            <div v-if="errors.invoice.date" class="error-message">{{this.errors.invoice.date}}</div>
          </div>
          <div class="form-group-invoice__sn relative">
            <input
              type="text"
              class="form-control"
              id="invoice-sn"
              v-model="client.invoice.serial"
              name="invoice-sn"
              maxlength="164"
              required
            />
            <span class="floating-label">Serie</span>
            <div v-if="errors.invoice.serial" class="error-message">{{this.errors.invoice.serial}}</div>
          </div>
          <div class="form-group-invoice__no relative">
            <input
              type="text"
              class="form-control"
              id="invoice-no"
              v-model="client.invoice.number"
              name="invoice-no"
              maxlength="64"
              required
            />
            <span class="floating-label">Număr</span>
            <div v-if="errors.invoice.number" class="error-message">{{this.errors.invoice.number}}</div>
          </div>
        </div>
      </div>
      <!-- Factura-->

      <div class="form-group">
        <input
          type="text"
          class="form-control"
          v-model="client.sn"
          id="serial"
          name="cod produs"
          maxlength="125"
          required
        />
        <span class="floating-label">Cod produs (serie)</span>
        <span class="input-icon">
          <svg
            version="1.1"
            id="barcode-icon"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="439.281px"
            height="439.281px"
            viewBox="0 0 439.281 439.281"
            style="enable-background:new 0 0 439.281 439.281;"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M31,58.283H5c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h26c2.762,0,5-2.238,5-5V63.283
                            C36,60.521,33.762,58.283,31,58.283z"
                />
                <path
                  d="M76.999,58.283h-22c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h22c2.762,0,5-2.238,5-5V63.283
                            C81.999,60.521,79.761,58.283,76.999,58.283z"
                />
                <path
                  d="M113.999,58.283h-11c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h11c2.762,0,5-2.238,5-5V63.283
                            C118.999,60.521,116.761,58.283,113.999,58.283z"
                />
                <path
                  d="M147.812,58.283h-12.625c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h12.625c2.762,0,5-2.238,5-5V63.283
                            C152.812,60.521,150.574,58.283,147.812,58.283z"
                />
                <path
                  d="M199.812,58.283h-20.625c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h20.625c2.762,0,5-2.238,5-5V63.283
                            C204.812,60.521,202.574,58.283,199.812,58.283z"
                />
                <path
                  d="M233.499,58.283h-10.313c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h10.313c2.763,0,5-2.238,5-5V63.283
                            C238.499,60.521,236.262,58.283,233.499,58.283z"
                />
                <path
                  d="M269.32,58.283h-12.807c-2.763,0-5,2.239-5,5v312.715c0,2.762,2.237,5,5,5h12.807c2.762,0,5-2.238,5-5V63.283
                            C274.32,60.521,272.082,58.283,269.32,58.283z"
                />
                <path
                  d="M321.5,58.283h-10.314c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5H321.5c2.762,0,5-2.238,5-5V63.283
                            C326.5,60.521,324.262,58.283,321.5,58.283z"
                />
                <path
                  d="M377,58.283h-21.461c-2.764,0-5,2.239-5,5v312.715c0,2.762,2.236,5,5,5H377c2.762,0,5-2.238,5-5V63.283
                            C382,60.521,379.762,58.283,377,58.283z"
                />
                <path
                  d="M434.281,58.283h-30.742c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h30.742c2.764,0,5-2.238,5-5V63.283
                            C439.281,60.521,437.043,58.283,434.281,58.283z"
                />
              </g>
            </g>
          </svg>
        </span>
        <label class="error-message" v-if="errors.sn">{{this.errors.sn}}</label>
      </div>

      <div class="form-group">
        <input
          type="text"
          class="form-control"
          v-model="client.product_name"
          id="product_name"
          name="product_name"
          maxlength="125"
          required
        />
        <span class="floating-label">Nume produs</span>
        <span class="input-icon">
          <svg
            version="1.1"
            id="barcode-icon"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="439.281px"
            height="439.281px"
            viewBox="0 0 439.281 439.281"
            style="enable-background:new 0 0 439.281 439.281;"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M31,58.283H5c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h26c2.762,0,5-2.238,5-5V63.283
                            C36,60.521,33.762,58.283,31,58.283z"
                />
                <path
                  d="M76.999,58.283h-22c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h22c2.762,0,5-2.238,5-5V63.283
                            C81.999,60.521,79.761,58.283,76.999,58.283z"
                />
                <path
                  d="M113.999,58.283h-11c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h11c2.762,0,5-2.238,5-5V63.283
                            C118.999,60.521,116.761,58.283,113.999,58.283z"
                />
                <path
                  d="M147.812,58.283h-12.625c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h12.625c2.762,0,5-2.238,5-5V63.283
                            C152.812,60.521,150.574,58.283,147.812,58.283z"
                />
                <path
                  d="M199.812,58.283h-20.625c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h20.625c2.762,0,5-2.238,5-5V63.283
                            C204.812,60.521,202.574,58.283,199.812,58.283z"
                />
                <path
                  d="M233.499,58.283h-10.313c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h10.313c2.763,0,5-2.238,5-5V63.283
                            C238.499,60.521,236.262,58.283,233.499,58.283z"
                />
                <path
                  d="M269.32,58.283h-12.807c-2.763,0-5,2.239-5,5v312.715c0,2.762,2.237,5,5,5h12.807c2.762,0,5-2.238,5-5V63.283
                            C274.32,60.521,272.082,58.283,269.32,58.283z"
                />
                <path
                  d="M321.5,58.283h-10.314c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5H321.5c2.762,0,5-2.238,5-5V63.283
                            C326.5,60.521,324.262,58.283,321.5,58.283z"
                />
                <path
                  d="M377,58.283h-21.461c-2.764,0-5,2.239-5,5v312.715c0,2.762,2.236,5,5,5H377c2.762,0,5-2.238,5-5V63.283
                            C382,60.521,379.762,58.283,377,58.283z"
                />
                <path
                  d="M434.281,58.283h-30.742c-2.762,0-5,2.239-5,5v312.715c0,2.762,2.238,5,5,5h30.742c2.764,0,5-2.238,5-5V63.283
                            C439.281,60.521,437.043,58.283,434.281,58.283z"
                />
              </g>
            </g>
          </svg>
        </span>
        <div class="error-message" v-if="errors.sn">{{this.errors.sn}}</div>
      </div>

      <div class="form-group">
        <input id="terms" type="checkbox" v-model="client.terms" required />
        <label class="terms" for="terms">
          <p>
            Am citit regulamentul campaniei promoționale, sunt de acord cu clauzele și doresc să particip sub condițiile puse.
          </p>
          <label v-if="errors.terms" class="error-message">{{this.errors.terms}}</label>
        </label>
      </div>
      <a @click="register" class="register-btn">Upgrade my system</a>
      <div class="success-message"></div>
    </form>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  data: () => {
    return {
      client: {
        fullname: "",
        phone: "",
        email: "",
        address: "",
        corporate: false,
        hardware: "",
        company: " ",
        pickup: "",
        pickup_time: "12:00:00",
        issue: "",
        sn: "",
        product_name: "",
        invoice: {
          serial: "",
          number: "",
          date: ""
        },
        terms: ""
      },
      errors: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        address: "",
        corporate: "",
        hardware: "",
        company: "",
        pickup: "",
        pickup_int: "",
        issue: "",
        accessories: "",
        sn: "",
        product_name: "",
        invoice: {
          serial: "",
          number: "",
          date: ""
        },
        terms: ""
      },
      success: "",
      valid: 1
    };
  },
  components: {
    Datepicker
  },
  mounted() {},
  methods: {
    customFormatter(date) {
      return moment(date).format("dd MM YYYY");
    },
    hardware: function(text, event) {
      if (text === "yes") this.client.hardware = true;
      else this.client.hardware = false;
    },
    corporate: function(event) {
      if (event.target.value === "pf") this.client.corporate = false;
      else this.client.corporate = true;
    },
    pickup: function(event) {
      if (event.target.value == "814") this.client.pickup_int = "8:00-14:00";
      else this.client.pickup_int = "14:00-20:00";
    },
    register: function(event) {
      this.errors = {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        address: "",
        corporate: "",
        hardware: "",
        company: "",
        pickup: "",
        pickup_int: "",
        issue: "",
        accessories: "",
        sn: "",
        product_name: "",
        invoice: {
          serial: "",
          number: "",
          date: ""
        },
        terms: ""
      };

      axios
        .post("api/register-upgrade", { attempt: this.client })
        .then(response => {
          console.log(response.data);
        })
        .catch(errors => {
          if (errors.response.data.errors["attempt.address"])
            this.errors.address =
              errors.response.data.errors["attempt.address"][0];
          if (errors.response.data.errors["attempt.fullname"])
            this.errors.fullname =
              errors.response.data.errors["attempt.fullname"][0];
          if (errors.response.data.errors["attempt.phone"])
            this.errors.phone = errors.response.data.errors["attempt.phone"][0];
          if (errors.response.data.errors["attempt.email"])
            this.errors.email = errors.response.data.errors["attempt.email"][0];
          if (errors.response.data.errors["attempt.corporate"])
            this.errors.corporate =
              errors.response.data.errors["attempt.corporate"][0];
          if (errors.response.data.errors["attempt.hardware"])
            this.errors.hardware =
              errors.response.data.errors["attempt.hardware"][0];
          if (errors.response.data.errors["attempt.pickup"])
            this.errors.pickup =
              errors.response.data.errors["attempt.pickup"][0];
          if (errors.response.data.errors["attempt.pickup_time"])
            this.errors.pickup_time =
              errors.response.data.errors["attempt.pickup_time"][0];
          if (errors.response.data.errors["attempt.issue"])
            this.errors.issue = errors.response.data.errors["attempt.issue"][0];
          if (errors.response.data.errors["attempt.sn"])
            this.errors.sn = errors.response.data.errors["attempt.sn"][0];
          if (errors.response.data.errors["attempt.terms"])
            this.errors.terms = errors.response.data.errors["attempt.terms"][0];
          if (errors.response.data.errors["attempt.invoice.serial"])
            this.errors.invoice.serial =
              errors.response.data.errors["attempt.invoice.serial"][0];
          if (errors.response.data.errors["attempt.invoice.product_name"])
            this.errors.invoice.product_name =
              errors.response.data.errors["attempt.invoice.product_name"][0];
          if (errors.response.data.errors["attempt.invoice.number"])
            this.errors.invoice.number =
              errors.response.data.errors["attempt.invoice.number"][0];
          if (errors.response.data.errors["attempt.invoice.date"])
            this.errors.invoice.date =
              errors.response.data.errors["attempt.invoice.date"][0];
        });
    }
  }
};
</script>
