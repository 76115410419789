<template>
    <div class="cta-section">
      <img class="cta-section__img" src="images/laptop-cta.png" alt="">
      <a href="#registration"><div class="mouse"></div></a>
      <img class="legion-badge" src="images/legion-badge.png" alt="Lenovo Legion Badge">
      <div class="container">
          <div class="cta-section__info">
            Înregistrează orice laptop Legion Y540, Y700 sau Y740 cumpărat<br>și alătură-te Legion of Heroes
          </div>
          <a class="cta-section__cta" href="#registration">Înregistrează<br>laptopul tău Legion</a>
      </div>
      
    </div>
</template>



<script>

  export default {
      
  }

</script>
