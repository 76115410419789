/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('magnific-popup');
require('multiple-select');
require('slick-carousel');
import GLightbox from 'glightbox';
import VCalendar from 'v-calendar';





window.Vue = require('vue');
Vue.use(VCalendar, {
    componentPrefix: 'v', // Use <vc-calendar /> instead of <v-calendar />
    popoverVisibility: 'focus',
    navVisibility: 'focus',
});
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


Vue.component('main-nav', require('./components/MainNav.vue').default);
Vue.component('video-header', require('./components/VideoHeader.vue').default);
Vue.component('main-cta', require('./components/MainCta.vue').default);
Vue.component('registration', require('./components/Registration.vue').default);
Vue.component('forms-section', require('./components/Forms.vue').default);
Vue.component('upgrader-form', require('./components/partials/UpgraderForm.vue').default);
Vue.component('healer-form', require('./components/partials/HealerForm.vue').default);
Vue.component('specs-section', require('./components/Specs.vue').default);
Vue.component('faq', require('./components/Faq.vue').default);
Vue.component('footer-lp', require('./components/FooterLp.vue').default);
Vue.component('return-product', require('./components/ReturnProduct.vue').default);
Vue.component('about-modal', require('./components/AboutModal.vue').default);
Vue.component('footer-lp', require('./components/FooterLp.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});


//Nav Menu Collapse & Hamburger
$(document).ready(function () {

    // Fire About Campaign popup on page load
    setTimeout(function() {
        if ($('#about-modal').length) {
            $.magnificPopup.open({
                items: {
                    src: '#about-modal' 
                },
                type: 'inline'
            });
          }
    }, 3000);

    //Hamburger Menu
    $('.hamburger').on('click', function () {
        $(this).toggleClass('open');
        $('.nav__menu').toggleClass('menu__mobile');
        $('.menu__mobile').slideDown(400);
    });

    //Mobile Menu Collapse
    $('.nav__menu > a').on('click', function () {
        if ($(window).width() < 990) {
            $('.hamburger').removeClass('open');
            $('.nav__menu').toggleClass('menu__mobile');
            $('.menu__mobile').slideUp(400);
        }
    });

    //Smooth Scroll
    $('a[href^="#"]').on('click', function (event) {
        if (!$(this).hasClass('noscroll--js')) {
            var target = $(this.getAttribute('href'));
            if (target.length) {
                event.preventDefault();
                $('html, body').stop().animate({
                    scrollTop: target.offset().top - 70
                }, 600);
            }
        }
        return false;
    });



    // $('a[href^="#"]').on('click', function(e) {
    //     if (!$(this).hasClass('noscroll--js')) {
    //         e.preventDefault();
    //         var target = $(this).attr("href");
    //         target = target.substring(target.indexOf('#'))
    //         $('html, body').stop().animate({
    //             scrollTop: $(target).offset().top - headerHeight
    //         }, 600, function() {
    //             location.hash = target;
    //         });
    //     }
    //     return false;
    // });

    //Nav Menu smaller on scroll
    function sticky() {
        var $window = $(window);
        var distance = 100;
        $window.scroll(function () {
            if ($window.scrollTop() >= distance) {
                $('.nav, .nav__logo').addClass('sticky');
            } else {
                $('.nav, .nav__logo').removeClass('sticky');
            }
        });
    }

    sticky();

    $(window).resize(function () {
        sticky();
    }).resize();

    //Modal Refund Form
    $(function () {
        $('.popup-modal').magnificPopup({
            type: 'inline',
            preloader: false,
            focus: '#username',
            modal: true,
            fixedContentPos: true,
            callbacks: {
                open: function () {
                    jQuery('body').addClass('noscroll');
                },
                close: function () {
                    jQuery('body').removeClass('noscroll');
                }
            }
        });
        $(document).on('click', '.popup-modal-dismiss', function (e) {
            e.preventDefault();
            $.magnificPopup.close();
        });
    });



    // Slick Carousel
    $('.specs-slider').slick({

        // Custom Arrows for Slick Carousel
        prevArrow: "<img class='a-left control-c prev slick-prev' src='../images/prev.png'>",
        nextArrow: "<img class='a-right control-c next slick-next' src='../images/back.png'>"
    });

    // Slick Carousel Slide Panel
    // $(".specs-slider__slide>.specs-slider__slide--cta").click(function () {
    //     $(".specs-slider__slide--panel").slideToggle("slow").toggleClass('specs-slider__slide--panel--open');
    // });

    //Product Specs Gallery Popup
    // $('.popup-gallery-1, .popup-gallery-2, .popup-gallery-3').magnificPopup({
    //     delegate: 'a',
    //     type: 'image',
    //     tLoading: 'Loading image #%curr%...',
    //     mainClass: 'mfp-img-mobile',
    //     gallery: {
    //         enabled: true,
    //         navigateByImgClick: true,
    //         preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
    //     },
    //     image: {
    //         tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
    //     }
    // });

    ////////////////////// Magnific Popup for videos
    // $('.popup-player').magnificPopup({
    //     type: 'iframe',
    //     mainClass: 'mfp-fade',
    //     removalDelay: 160,
    //     preloader: false,
    //     fixedContentPos: false,
    //     iframe: {
    //         markup: '<div class="mfp-iframe-scaler">' +
    //             '<div class="mfp-close"></div>' +
    //             '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
    //             '</div>',
    //         srcAction: 'iframe_src',
    //     }
    // });


    // Image / Video Lightbox
    const lightbox = GLightbox({
        selector: 'glightbox',
        touchNavigation: true,
        loopAtEnd: true,
        autoplayVideos: true
    });



    ////////////////////// Accordion
    (function ($) {

        var allPanels = $('.faq__accordion > .faq__accordion--element > dd').hide();

        $('.faq__accordion > .faq__accordion--element > dt > a').click(function () {
            allPanels.slideUp();
            $(this).parent().next().slideDown();
            $(this).parent().next().addClass("isActiveAcc").css({
                'background': '#fff',
                'color': '#3e8cde'
            });
            $(this).parent().addClass("isActiveAcc");
            $(this).parentsUntil(".faq__accordion").removeClass("isActiveAcc");
            return false;
        });

    })(jQuery);


});






//////DATE FORMAT WHILE TYPING
var isShift = false;
var seperator = "-";
window.onload = function () {
    //Reference all INPUT elements in the Table.
    var inputs = document.getElementsByClassName("date-valid");

    //Loop through all INPUT elements.
    for (var i = 0; i < inputs.length; i++) {
        //Check whether the INPUT element is TextBox.
        if (inputs[i].type == "text") {
            //Check whether Date Format Validation is required.
            if (inputs[i].className.indexOf("date-valid") != 1) {

                //Set Max Length.
                inputs[i].setAttribute("maxlength", 10);

                //Only allow Numeric Keys.
                inputs[i].onkeydown = function (e) {
                    return IsNumeric(this, e.keyCode);
                };

                //Validate Date as User types.
                inputs[i].onkeyup = function (e) {
                    ValidateDateFormat(this, e.keyCode);
                };
            }
        }
    }
};

function IsNumeric(input, keyCode) {
    if (keyCode == 16) {
        isShift = true;
    }
    //Allow only Numeric Keys.
    if (((keyCode >= 48 && keyCode <= 57) || keyCode == 8 || keyCode <= 37 || keyCode <= 39 || (keyCode >= 96 && keyCode <= 105)) && isShift == false) {
        if ((input.value.length == 2 || input.value.length == 5) && keyCode != 8) {
            input.value += seperator;
        }

        return true;
    } else {
        return false;
    }
};

function ValidateDateFormat(input, keyCode) {
    var dateString = input.value;
    if (keyCode == 16) {
        isShift = false;
    }
    var regex = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/;
};

// Multiple Select Field
$(function () {
    $('.multiple-select').multipleSelect()
})



// Forms Animations

// Append or Prepend more divs -- emulates adding more ::before / ::after elements on the same div
$(".upgrade__tabs--right__cta").append(' <div class="polygon-right__top"></div> ');
$(".polygon-right__top").append(' <div class="polygon-right__before"></div> ');

$(".upgrade__tabs--left__cta").append(' <div class="polygon-left__top"></div> ');
$(".polygon-left__top").append(' <div class="polygon-left__before"></div> ');

// Load page with left side opened
$(".upgrade__tabs--left__cta").addClass("upgrade__tabs--left__cta--active");
$(".polygon-left__top").addClass("slide-in-2");
$("#healer").delay(2000).addClass("visible");

//  Tabs click logic
$(".upgrade__tabs--left__cta, .nav-heal-open, .upgrade__tabs--left").click(function () {
    $(".upgrade__tabs--left__cta").addClass("upgrade__tabs--left__cta--active");
    $(".polygon-left__top").addClass("slide-in-2");
    $(".polygon-left__top::before").addClass("slide-in-3");
    $(".polygon-left__top::after").addClass("slide-in-4");

    // Forms activation
    $("#healer").delay(2000).addClass("visible");
    $("#upgrader").removeClass("visible");

    $(".polygon-right__top").addClass("slide-hidden");
    if ($(".polygon-right__top").hasClass("slide-hidden")) {
        // Remove Right Side
        $(".upgrade__tabs--right__cta").removeClass("upgrade__tabs--right__cta--active");
        $(".polygon-right__top").addClass("slide-in-2-out");
        $(".polygon-right__top::before").addClass("slide-in-3-out");
        $(".polygon-right__top::after").addClass("slide-in-4-out");
        $(".polygon-left__top").removeClass("slide-in-2-out");
        $(".polygon-left__top::before").removeClass("slide-in-3-out");
        $(".polygon-left__top::after").removeClass("slide-in-4-out");
    }
});




$(".upgrade__tabs--right__cta, .nav-upgrade-open, .upgrade__tabs--right").click(function () {
    $(".upgrade__tabs--right__cta").addClass("upgrade__tabs--right__cta--active");
    $(".polygon-right__top").addClass("slide-in-2");
    $(".polygon-right__top::before").addClass("slide-in-3");
    $(".polygon-right__top::after").addClass("slide-in-4");

    // Forms activation
    $("#upgrader").delay(2000).addClass("visible");
    $("#healer").removeClass("visible");

    $(".polygon-left__top").addClass("slide-hidden");
    if ($(".polygon-left__top").hasClass("slide-hidden")) {
        // // Remove left Side
        $(".upgrade__tabs--left__cta").removeClass("upgrade__tabs--left__cta--active");
        $(".polygon-left__top").addClass("slide-in-2-out");
        $(".polygon-left__top::before").addClass("slide-in-3-out");
        $(".polygon-left__top::after").addClass("slide-in-4-out");
        $(".polygon-right__top").removeClass("slide-in-2-out");
        $(".polygon-right__top::before").removeClass("slide-in-3-out");
        $(".polygon-right__top::after").removeClass("slide-in-4-out");
    }
});


// Force video controls off and loop on all browsers
var vids = $("video");
$.each(vids, function () {
    this.controls = false;

    vids.prop("controls", false);

    $(this).bind('ended', function () {
        this.play();
    });
});


// Make placeholder rise up on new vue datepicker inputs
$('input').change(function () {
    if ($(this).val()) {
        $(this).parent().next("span.floating-label").addClass("placeholder-switch");
    } else {
        $(this).parent().next("span.floating-label").removeClass("placeholder-switch");
    }
});


$("input").focus(function () {
    $(this).parent().next("span.floating-label").addClass("placeholder-switch");
});

$("input").focusout(function () {
    $(this).parent().next("span.floating-label").addClass("placeholder-switch");
});

$("input").focusout(function () {
    if ($(this).val() != 0) {
        $(this).parent().next("span.floating-label").addClass("placeholder-switch");
    } else {
        $(this).parent().next("span.floating-label").removeClass("placeholder-switch");
    }
});

$('input').blur(function () {
    if (!$(this).val()) {
        $(this).parent().next("span.floating-label").addClass("placeholder-switch");
    } else {
        $(this).parent().next("span.floating-label").removeClass("placeholder-switch");
    }
});


// Change header video on mobile resolution
$(function () {
    if ($(window).width() < 990) {
        var videoFile = 'images/video-header.mp4';
        $('#header-video').attr('src', videoFile);
    } else if ($(window).width() > 990) {
        var videoFile = 'images/animatie_legion.mp4';
        $('#header-video').attr('src', videoFile);
    }
});


window.addEventListener("resize", function () {
    if ($(window).width() < 990) {
        var videoFile = 'images/video-header.mp4';
        $('#header-video').attr('src', videoFile);
    }
});
